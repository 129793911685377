<template>
  <button
    class="button"
    type="button"
    :class="{
      button_small: small,
      button_loader: loader,
      button_secondary: secondary,
    }"
    :disabled="loader"
  >
    <slot></slot>
  </button>
</template>

<script>
import './button.scss';

export default {
  name: 'IButton',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    loader: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
  }
};
</script>
