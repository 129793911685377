<template>
  <div class="add-to-cart-warning">
    <div class="cart-warning">
      <div class="warning-icon"></div>
    </div>
    <h3>Внимание!</h3>
    <h4>Такой кадастровый номер уже есть в корзине</h4>
    <Button class="button button_small" @click.native="goToCart">
      Посмотреть
    </Button>
  </div>
</template>

<script>
import popup from '@/mixins/popup';
import Button from '@/components/gui/button/Button';

export default {
  name: 'AddToCartWarning',
  components: {
    Button
  },
  mixins: [popup],
  methods: {
    goToCart() {
      this.$store.commit('popup/close');
      this.$router.push('/cart');
    }
  }
};
</script>

<style lang="scss">
.add-to-cart-warning {
  .cart-warning {
    display: block;
    margin: 20px auto 30px;
    width: 70px;
    min-width: 70px;
    height: 35px;
    background-color: $brand;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-left: 35px solid transparent;
      border-right: 35px solid transparent;
      border-bottom: 20px solid $brand;
      transform: translateY(-100%);
    }

    &::after {
      top: initial;
      bottom: 0;
      transform: translateY(100%);
      border-bottom: 0;
      border-top: 20px solid $brand;
    }

    .warning-icon {
      position: absolute;
      background-image: url('./assets/info.svg');
      background-repeat: no-repeat;
      width: 35px;
      height: 35px;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  h3 {
    font-size: 30px;
    text-align: center;
    margin: 0;
  }

  h4 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin: 10px 0 20px;
  }

  .button {
    display: block;
    margin: 0 auto;
  }
}
</style>
